.label-default {
  background-color: $btn__color__default-background;
}

.label-primary {
  background-color: $btn__color__primary-background;
}

.label-success {
  background-color: $btn__color__success-background;
}

.label-warning {
  background-color: $btn__color__warning-background;
}

.label-danger {
  background-color: $btn__color__danger-background;
}

.label-info {
  background-color: $btn__color__info-background;
}
