@mixin centerXY() {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerX() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin truncate($width: 100%) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin threePerRow() {
  float: left;
  width: 32%;
  margin-left: 2%;

  &:first-child,
  &:nth-child(3n + 1) {
    margin-left: 0;
  }
}

@mixin twoPerRow() {
  float: left;
  width: 49%;
  margin-left: 2%;

  &:first-child,
  &:nth-child(2n + 1) {
    margin-left: 0;
  }
}

@mixin placeholder($color: $color-placeholder) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}
