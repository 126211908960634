.account-headquarters {
  padding-top: ms(8);

  @media screen and (max-width: $screen-md) {
    padding-top: ms(7);
  }
}

.alert {
  margin-top: 3.5em;
}

.pagination {
  font-size: ms(-1);
}

.list-style-disc {
  list-style-type: disc;
}

.list-style-disc > li {
  margin-left: 20px;
}

.list-group-item.active,
.list-group-item.active:hover {
  background: lighten($card__color-accent, 20%);
  border-color: lighten($card__color-accent, 10%);
}

.popover {
  min-width: 250px;
}
