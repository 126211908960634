// Breakpoint definitions. Typi plugin relies on this variable. Please do not change.
$breakpoints: (
  xs: 320px,
  sm: 768px,
  md: 990px,
  lg: 1170px,
  xl: 1380px,
  xxl: 2000px
);

// Bootstrap overrides
$screen-xs-min: map-get($breakpoints, xs);
$screen-sm-min: map-get($breakpoints, sm);
$screen-md-min: map-get($breakpoints, md);
$screen-lg-min: map-get($breakpoints, lg);
$screen-xl-min: map-get($breakpoints, xl);

@mixin respond-to($media) {
  @if ($media == handhelds) {
    @media only screen and (max-width: map-get($breakpoints, xs)) {
      @content;
    }
  } @else if ($media == smaller-screens) {
    @media only screen and (min-width: map-get($breakpoints, xs) + 1) and (max-width: map-get($breakpoints, sm) - 1) {
      @content;
    }
  } @else if ($media == small-screens) {
    @media only screen and (min-width: map-get($breakpoints, sm) + 1) and (max-width: map-get($breakpoints, md) - 1) {
      @content;
    }
  } @else if ($media == medium-screens) {
    @media only screen and (min-width: map-get($breakpoints, md) + 1) and (max-width: map-get($breakpoints, lg) - 1) {
      @content;
    }
  } @else if ($media == large-screens) {
    @media only screen and (min-width: map-get($breakpoints, lg) + 1) and (max-width: map-get($breakpoints, xl) - 1) {
      @content;
    }
  } @else if ($media == extra-large-screens) {
    @media only screen and (min-width: map-get($breakpoints, xl) + 1) and (max-width: map-get($breakpoints, xxl) - 1) {
      @content;
    }
  } @else if ($media == huge-screens) {
    @media only screen and (min-width: map-get($breakpoints, xxl)) {
      @content;
    }
  }
}

@mixin larger-than($media) {
  @if ($media == small-screens) {
    @media only screen and (min-width: map-get($breakpoints, md)) {
      @content;
    }
  } @else if ($media == medium-screens) {
    @media only screen and (min-width: map-get($breakpoints, lg)) {
      @content;
    }
  } @else if ($media == large-screens) {
    @media only screen and (min-width: map-get($breakpoints, xl)) {
      @content;
    }
  }
}

@mixin smaller-than($media) {
  @if ($media == small-screens) {
    @media only screen and (max-width: map-get($breakpoints, sm)) {
      @content;
    }
  } @else if ($media == medium-screens) {
    @media only screen and (max-width: map-get($breakpoints, md)) {
      @content;
    }
  }
}
