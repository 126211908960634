@import '../settings/colors';
@import '../settings/typography';

$nav-height: 50px;

.nav-global {
  // Most of this under the .nav-global class is to override Bootstrapv5's default navbar styles, since as of 11/'24 global nav uses BSv3 and focus uses v5. If/when global nav is updated to BSv5, start here when updating/removing the styles.
  &.navbar {
    flex-wrap: nowrap;
  }
  .navbar-brand {
    padding: 0;
  }
  .nav-dropdown-indented {
    padding: 0 0.3rem 0 0.9rem;
  }
  .container {
    margin: 0;
    max-width: 100%;
    width: 100%;
    display: inline-block;
  }
  .navbar-right {
    display: flex;
    flex-direction: row;
  }
  .nav-align {
    padding-top: 6px;
  }
  // hide extra caret added by Bootstrap
  .dropdown-toggle::after {
    display: none;
  }
  .navbar-toggle {
    margin-top: 0.5em;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-nav > li > a {
    color: $color-action-01 !important;
    padding-top: 8px;
    padding-bottom: 8px;

    &:hover {
      text-decoration: none;
    }
  }
}

.nav-left {
  line-height: $nav-height;
  float: left;
  margin: 0;

  h1 {
    font-size: 24px;
  }
}

.nav-right {
  line-height: $nav-height;
  float: right;
  display: inline;
}

.navbar-toggle {
  background: $color-white;
  border: 1px solid $color-neutral-01;

  img {
    padding-left: 15px;
  }

  .icon-bar {
    background: $color-neutral-01;
  }
}

.nav-no-link {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  height: $nav-height;
  margin: 0 2px;
}

.info-controls {
  height: $nav-height;
  line-height: $nav-height;
}

.vertical-line {
  margin-top: 8px;
  height: 33px;
  border-left: 1px solid $color-neutral-03;
  margin-right: 15px;
}

.label {
  margin-left: 5px;
}

// Add lines between dropdown list items
.dropdown-toggle::after {
  color: $color-neutral-03;
}

// Utilize different colored navs to help devs see which environment they're working in.
.environment-dev nav.nav-level-one {
  background-color: $color-data-04;
}

.environment-staging nav.nav-level-one {
  background-color: $color-caution-01;
}

.nav-level-one,
.nav-level-two,
.nav-level-three {
  background: $color-white;
  border-bottom: 1px solid $color-neutral-04;
  font-size: 14px;
  height: $nav-height;
  position: fixed;
  width: 100%;
  background: #fff;
  right: 0;
  left: 0;
  margin: 0;

  .nav-last-item {
    padding-right: 0;
  }

  a {
    color: $color-action-01 !important;

    &:hover {
      text-decoration: none;
    }
  }
}

.nav-level-one {
  z-index: 10;
  top: 0;
  padding-left: 15px;
  padding-right: 15px;

  .caret {
    color: $color-neutral-03;
  }

  .caret-dropdown {
    color: $color-neutral-03;
  }

  .dropdown-menu {
    font-size: 14px;

    .divider {
      margin: 2px 0;
    }

    // Prevent last dropdown-menu from flowing off of the page
    &.last {
      width: auto;
      right: 0;
      left: initial;
    }
  }
}

.nav-level-two {
  z-index: 9;
  top: $nav-height;

  .info-controls .nav-last-item {
    padding-right: 15px;
    padding-left: 7px;
  }

  .target-overlay-content a {
    color: $color-link-text;
  }
}

.nav-level-three {
  z-index: 8;
  top: $nav-height + $nav-height;

  .nav-left {
    font-size: 18px;
    font-family: $font-family-montserrat-light;
  }
}

.axiom-logo {
  height: $nav-height;
  max-height: 100%;
  padding: 5px 0;
  width: 71px;
  vertical-align: middle;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $nav-height;
}

.strata-logo {
  width: 7rem;

  &__nav {
    max-height: 100%;
    padding-top: 8px;
    width: 71px;
    vertical-align: middle;
  }
  &__text {
    font-size: 0.7em;
    color: $color-neutral-01;
    padding-left: 4px;
    padding-top: 1px;
  }
}

@media screen and (max-width: 1250px) {
  .navbar-header {
    padding-left: 15px;
  }

  .navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
  }

  .nav-level-one {
    height: auto;
    padding: 0;
  }

  .logo-container {
    padding-left: 15px;
  }

  .navbar-collapse {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    border-top: 1px solid transparent;
    background: $color-white;
    float: left;
    width: 105%;
    padding: 0;
    margin: 0;

    &.collapse {
      display: none !important;

      &.in {
        display: block !important;
      }
    }

    .nav-right {
      float: left;
      width: 100%;
    }

    .nav-align {
      padding-top: 0;
    }

    .navbar-nav {
      float: none !important;

      li {
        float: none;

        > a {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
