#map_options {
  padding: 5px;
}
.map-legends .map-legend {
  padding: 5px !important;
}

#map2 {
  height: 500px;
  width: 100%;

  .map-option-control {
    margin: 0;
  }
}

#map2_container {
  position: relative;
  top: 0;
  bottom: 0;
}
#map2_overlay,
#table_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(255, 255, 255, 0.5);

  img {
    display: block;
    padding: ms(-2);
    background: $color-white;
    border-radius: 50%;
    border: 1px solid $color-light-gray;
    @include centerXY();
  }
}

#details {
  font-size: ms(-1);

  #scrollable {
    position: relative;
  }

  .table th {
    font-size: ms(-1);
  }

  .totals td,
  .totals th {
    background: $color__accent-quaternary;
    color: $color-white;
  }

  tr:not(.zipcode) td {
    cursor: move;
  }
}

#zipcode-search-control {
  display: none;

  input {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    width: 180px;
    padding: 0 0 0 ms(-1);
    height: 26px;
    outline: 0 none;
  }

  &.active {
    display: block;
  }
}

.map-legend ul.legend-labels li {
  display: block;
  float: left;
  width: 50%;
  text-align: center;
  font-size: 80%;
  list-style: none;

  span {
    display: block;
    float: left;
    height: ms(0);
    width: 100%;
  }

  .legend-primary {
    background: $color__brand-primary;
  }
  .legend-secondary {
    background: $color__brand-secondary;
  }
}
