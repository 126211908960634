.fa-spin {
  animation-duration: $transition-speed * 5 !important;
}

.badge {
  height: ms(2);
  width: ms(2);
  padding: ms(-8) 0 0;
  font-size: ms(-2);
  font-weight: 400;
  border-radius: 50%;
  background: $color__accent-tertiary;
}

.breadcrumb {
  padding: ms(-1);
  margin-bottom: ms(-1);
}

#sag-name {
  display: inline;
}
#sag-name-form {
  display: none;
}

#sag-name-form.active {
  display: inline-block;
  vertical-align: middle;
}
#sag-name-form .col-sm-12 {
  padding: 0;
}
#sag-name-form label {
  display: none;
}
#sag-name-form.form-group::before {
  display: none;
}
.form-group.has-error {
  position: relative;
  top: 15px;
}
