.login-logo-container {
  /* Inside auto layout */
  flex: none;
  order: 0;
  text-align: center;
}

.login-logo {
  max-width: 120px;
  height: auto;
}

.login-header-text {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
}

.form-label {
  font-weight: bold;
}
