@import '../settings/colors';

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: $color-neutral-05;
//** Global text color on `<body>`.
$text-color: $color-neutral-01;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1170 !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family-serif: Georgia, 'Times New Roman', Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

// $font-size-base:          ms(0);
// $font-size-large:         ms(2);
// $font-size-small:         ms(-1);

// $font-size-h1:            ms(5);
// $font-size-h2:            ms(4);
// $font-size-h3:            ms(3);
// $font-size-h4:            ms(2);
// $font-size-h5:            ms(1);
// $font-size-h6:            ms(0);

//** Unit-less `line-height` for use in components like buttons.
// $line-height-base:        ms(0) * 1.4;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
// $line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

// $gray-base:              #000 !default;
// $gray-darker:            lighten($gray-base, 13.5%) !default; // #222
// $gray-dark:              lighten($gray-base, 20%) !default;   // #333
// $gray:                   lighten($gray-base, 33.5%) !default; // #555
// $gray-light:             lighten($gray-base, 46.7%) !default; // #777
// $gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary: $color__brand-primary !default;
$brand-success: $color__brand-secondary !default;
$brand-info: $color__brand-tertiary !default;
$brand-warning: $color-warning !default;
$brand-danger: $color-danger !default;

$border-radius-base: 4px;

//== Links
$link-color: $color-link-text;
$link-hover-color: $color-link-text-hover;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-hover-bg: $color-action-04;

//== Navbar

// $navbar-height:                    50px !default;
// $navbar-margin-bottom:             $line-height-computed !default;
// $navbar-border-radius:             $border-radius-base !default;
// $navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
// $navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
$navbar-collapse-max-height: 400px;

$navbar-default-bg: $color-white;
$navbar-default-border: $color-neutral-04;

// // Navbar links
$navbar-default-link-color: $color-neutral-01;
// $navbar-default-link-hover-color: lighten($color-white, 5%);
$navbar-default-link-hover-bg: $color-action-04;
// $navbar-default-link-active-color: transparent;
$navbar-default-link-active-bg: $color-action-04;
// $navbar-default-link-disabled-color:       #ccc !default;
// $navbar-default-link-disabled-bg:          transparent !default;

// // Navbar brand label
// $navbar-default-brand-color:               $navbar-default-link-color !default;
// $navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) !default;
// $navbar-default-brand-hover-bg:            transparent !default;

// // Navbar toggle
// $navbar-default-toggle-hover-bg:           #ddd !default;
// $navbar-default-toggle-icon-bar-bg:        #888 !default;
// $navbar-default-toggle-border-color:       #ddd !default;

//== Forms
//
//##

//** `<input>` background color
// $input-bg:                       #fff !default;
// //** `<input disabled>` background color
// $input-bg-disabled:              $gray-lighter !default;

//** Text color for `<input>`s
$input-color: $color-black;
// //** `<input>` border color
// $input-border:                   #ccc !default;

// // TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
// //** Default `.form-control` border radius
// // This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
// $input-border-radius:            $border-radius-base !default;
// //** Large `.form-control` border radius
// $input-border-radius-large:      $border-radius-large !default;
// //** Small `.form-control` border radius
// $input-border-radius-small:      $border-radius-small !default;

// //** Border color for inputs on focus
// $input-border-focus:             #66afe9 !default;

// //** Placeholder text color
$input-color-placeholder: $color-light-gray;

// //** Default `.form-control` height
// $input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
// //** Large `.form-control` height
// $input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
// //** Small `.form-control` height
// $input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

//== Buttons

// $btn-font-weight:                normal !default;

// $btn-default-color:              #333 !default;
// $btn-default-bg:                 #fff !default;
// $btn-default-border:             #ccc !default;

$btn-primary-color: $color__brand-primary;
// $btn-primary-bg:                 $brand-primary !default;
// $btn-primary-border:             darken($btn-primary-bg, 5%) !default;

// $btn-success-color:              #fff !default;
// $btn-success-bg:                 $brand-success !default;
// $btn-success-border:             darken($btn-success-bg, 5%) !default;

// $btn-info-color:                 #fff !default;
// $btn-info-bg:                    $brand-info !default;
// $btn-info-border:                darken($btn-info-bg, 5%) !default;

// $btn-warning-color:              #fff !default;
// $btn-warning-bg:                 $brand-warning !default;
// $btn-warning-border:             darken($btn-warning-bg, 5%) !default;

// $btn-danger-color:               #fff !default;
// $btn-danger-bg:                  $brand-danger !default;
// $btn-danger-border:              darken($btn-danger-bg, 5%) !default;

// $btn-link-disabled-color:        $gray-light !default;

// // Allows for customizing button radius independently from global border radius
$btn-border-radius-base: 4px;
$btn-border-radius-large: 4px;
$btn-border-radius-small: 4px;

//== Dropdowns

//** Dropdown link text color.
$dropdown-link-color: $color-neutral-01;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($color-neutral-01, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg: $color-action-04;

//== Breakpoints
$grid-float-breakpoint: $screen-md;
$grid-float-breakpoint-max: $screen-md;
