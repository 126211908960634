ul.dropdown-menu.annotated {
  min-width: 220px;
}

ul.dropdown-menu.annotated li {
  text-align: center;
}

ul.dropdown-menu.annotated li:hover {
  background-color: #f5f5f5;
}

ul.dropdown-menu.annotated li a {
  padding-bottom: 0;
}

// So top of header is inline with subnav
.header-inline {
  margin-top: -8px;
}
