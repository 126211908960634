@font-face {
  font-family: 'Montserrat';
  src: url('/static/fonts/montserrat-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Light';
  src: url('/static/fonts/montserrat-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
