$card-padding: ms(0) !default;

/**
 * Card component – Analogous to the panel component in bootstrap.
 *
 * Using the card class will create a container w/ padding for content. Including
 * the card-sections class will allow for separate headers, footers, and body sections.
 */
.card {
  margin: 0 0 ($card-padding * 1.5);
  padding: $card-padding;
  background: $card__color-background;
  border-radius: $border-radius-base;
  border: 1px solid $color-neutral-04;
  overflow: hidden;
  width: 100%;

  .header-border {
    margin: 0 0 ms(-1);
    padding-bottom: ms(-3);
    border-color: $color-light-gray;
  }

  .card-tabs {
    font-weight: 500;
    @include font-size-map(1);

    li > a {
      border-radius: 0;
    }
  }

  &.card-sections {
    padding: 0;

    .card-section {
      padding: $card-padding;
    }

    .card-header {
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
      border-bottom: 1px solid $card__color-accent;
      background: $card__color-accent;
      color: $card__color-header;
    }

    .card-footer {
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
      border-top: 1px solid $card__color-accent;
    }
  }

  .copy__body-link,
  .copy__body a {
    color: $link__color;
    border-color: $link__color;

    &:hover,
    &:active {
      color: $link__color-active;
      text-decoration: none;
      border-color: transparent;
    }
  }
}

.card-header,
.card-footer {
  background: $card__color-section;
}

.inactive {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;

  h3 {
    color: $table__color-header-border;
  }

  svg {
    color: $table__color-header-border;
  }
}

.active {
  .card {
    &:hover {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
      transition-timing-function: ease-out;
      transform: perspective(1px) translateZ(0);
      transition-duration: 0.3s;
      transition-property: box-shadow;
      -webkit-tap-highlight-color: transparent;

      .card-icon {
        color: $link__color-active-dark;
      }
    }

    p {
      margin: 0;
    }
  }

  .card-icon {
    color: $card__color-accent;
  }
}

@media screen and (min-width: $screen-sm) {
  .flex {
    display: flex;
  }
}

.panel {
  .panel-body,
  .panel-heading {
    padding: ms(0);
  }

  .panel-title {
    font-weight: 400;
  }

  &.panel-default {
    border-color: $card__color-accent;

    .panel-heading {
      color: $card__color-header;
      background: $card__color-accent;
      border-color: darken($card__color-accent, 5%);
    }
  }

  .panel-heading-collapse a:after {
    content: '▲';
    float: right;
    transform: scaleX(2);
    font-size: ms(-2);
    margin-top: 5px;
    margin-right: ms(0);
    opacity: 0.7;
  }

  .panel-heading-collapse a.collapsed:after {
    content: '▼';
  }
}

.panel-bulk-action-container {
  padding: 0 ms(0);
  margin: 0 (-ms(0)) 0 (-ms(0));
  background: $color-white;
  max-height: 0px;
  overflow: hidden;
  transition: max-height $transition-speed-easing,
    padding $transition-speed-easing, margin $transition-speed-easing;

  &.active {
    max-height: 300px;
    padding: ms(0);
    margin: (-ms(0)) (-ms(0)) 0;
  }

  .bulk-action-btn-container {
    margin: 0 auto;
  }

  p {
    display: inline-block;
    margin: 0;
    font-size: ms(-1);
    font-weight: 700;
    color: $color__accent-quaternary;
  }
}
