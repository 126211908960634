body {
  padding-top: ms(7);
  padding-bottom: ms(1);
  overflow-x: hidden;
}

.container {
  width: 100%;
}

section {
  margin-bottom: ms(5);

  &.section-border {
    border-bottom: 1px dashed $color-copy;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-with-disc {
  list-style-type: disc;
}

ol {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

footer {
  text-align: center;

  .svg-icon {
    width: ms(10);
    max-height: ms(2);
    margin: 0 auto;
  }
}

footer,
#push {
  height: ms(5);
}

#wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto (-ms(5)); // Negative indent footer by it's height
}

.header-row h1 {
  margin-top: 0;
}

img {
  max-width: 100%;
}
