@import './settings/colors';

// While developing UI, we want to account for where Intercom covers up the UI in prod.
// All styles except the empty content are copied from Intercom's styles in prod.

body.environment-dev:after {
  position: fixed;
  z-index: 2147483003;
  bottom: 20px;
  right: 20px;
  max-width: 48px;
  width: 48px;
  max-height: 48px;
  height: 48px;
  border-radius: 50%;
  background: $color-brand-01;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  content: ' ';
}
