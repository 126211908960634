$color-blue-dark-moderate: #396da2;

#upload-error-panel > .panel-danger > .panel-heading {
  background-color: #f2dede !important;
  border-color: #ebccd1 !important;
  color: #a94442 !important;
}

#upload-error-grid .ag-header {
  font-family: 'Lato';
  font-size: 1rem;
  --ag-header-background-color: #f2dede;
  border: 1.25px solid #ebccd1 !important;
}

#upload-error-grid .ag-header-cell-text {
  color: #a94442 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.crosswalk-create-modal .modal-dialog {
  margin-top: 15rem;
  font-size: 1rem;
}

table
  .crosswalk-create-modal
  > .modal-dialog
  > .modal-content
  > .modal-body
  > .btn {
  line-height: 1.2;
  font-size: 0.69444rem;
}

.crosswalk-create-modal .modal-footer > .btn {
  line-height: 1.2;
}

.ag-cell {
  font-family: 'Lato';
  font-size: 1rem;
}

.ag-theme-alpine .ag-header {
  font-family: 'Lato';
  font-size: 1rem;
  --ag-header-background-color: #396da240;
  border: 1.25px solid #396da2 !important;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}

.alt-row {
  --ag-odd-row-background-color: #396da240;
}

.red-bg {
  input {
    border: var(--ag-borders-input-invalid) var(--ag-input-border-color-invalid) !important;
  }
}
