@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

.map-legend {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  font: 14px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  z-index: 1;
  color: #777;
  line-height: 1.42857;

  p {
    margin: 0 0 10px;
    color: #777;
  }

  div span {
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    margin-right: 5px;
    width: 12px;
  }
}

.info-control {
  min-width: 150px;
  overflow: auto;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  font: 14px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  z-index: 1;
  color: #777;
}

#map2 {
  .mapboxgl-popup-content {
    background-color: rgba(20, 20, 20, 0.8);
    color: white;
    padding: 5px;
  }

  .mapboxgl-popup-tip {
    border-top-color: rgba(20, 20, 20, 0.8);
    display: none;
  }
}
