#pld_name {
  display: inline;
}
#pld_name_form {
  display: none;
}
#pld_name_form.active {
  display: inline-block;
  vertical-align: middle;
}
#pld_name_form .col-sm-12 {
  padding: 0;
}
.form-group.has-error {
  position: relative;
  top: 15px;
}
#pld_name_form label {
  display: none;
}
#pld_name_form.form-group:before {
  display: none;
}
