// Canvas styles
$theme: 'ocean';

@import '../../../assets/stylesheets/main';
// Temporary Explorer
@import '../../../assets/stylesheets/lib/chosen';

// Tablesorter sorting icons
@import '../../../assets/lib/tablesorter/tablesorter';

@import 'layout';
@import 'product-line';
@import 'about-dataset';
@import 'map';
@import 'dashboard';
@import 'user-crosswalk';
@import 'rate-crosswalk-groups';
@import 'apcd-metrics';

.filter-select {
  flex: 1;
  height: 40px; /* Set a fixed height */
  margin-right: 10px; /* Add some space between the elements */
}
