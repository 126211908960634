.table {
  th input[type='checkbox'],
  td input[type='checkbox'] {
    margin-right: ms(-3);
  }

  tbody > tr > td {
    font-size: ms(-1);
    padding-top: ms(-3);
    padding-bottom: ms(-3);
    vertical-align: middle;
    border-color: $table__color-header-border;

    .btn {
      font-size: ms(-1);
      line-height: 1.6;

      .fa {
        vertical-align: baseline;
      }
    }
  }

  thead > tr > th {
    border-color: $table__color-border;
  }

  &.table-bordered th,
  &.table-bordered th:hover {
    border-left-color: $table__color-header-border;
    border-right-color: $table__color-header-border;
  }
}
