form {
  margin-top: calc-ems($line-height / ($ms-ratio * 2));
  margin-bottom: calc-ems($line-height / ($ms-ratio));
}

#blackbird-dashboard form {
  margin-top: 0;
}

label {
  font-size: ms(-1);
  font-weight: 600;
  margin-bottom: ms(-3);
}

.form-control {
  height: ms(5);
  padding: 0 ms(0);
  color: $color-form-text;
  box-shadow: none;
  border: 1px solid darken($color-white, 5%);
  font-size: ms(-1);
  transition: border $transition-speed-easing;
  @include placeholder($color-placeholder);

  &:focus {
    box-shadow: none;
    border-color: mix($color__brand-tertiary, $color-white, 50%);
  }
}

textarea.form-control {
  padding: ms(0);
}

.form-margin {
  margin-top: 38px;

  form {
    margin-top: 0;
  }
}

.form-no-margin {
  margin-top: 0;
}

.input-group-addon {
  height: ms(5);
  padding: 0 ms(-1);
  color: $color-placeholder;
  border: 1px solid darken($color-white, 5%);
  line-height: $line-height;
  font-size: ms(-1);
}

.btn-block {
  margin-bottom: 10px;
}

.btn,
.btn-default {
  height: auto;
  font-size: ms(-1);
  font-weight: 400;
  line-height: $line-height;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $btn__color__default;
  background: $btn__color__default-background;
  border: 1px solid $btn__color__default-accent;
  transition: background $transition-speed-easing,
    border $transition-speed-easing;

  .copy__icon,
  .fa {
    vertical-align: middle;
    font-size: ms(0);
    line-height: 1;
  }

  .caret {
    margin-top: -1px;
  }

  @include respond-to(huge-screens) {
    line-height: 1.6;

    .copy__icon,
    .fa {
      vertical-align: baseline;
    }
  }

  &:focus,
  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &.active:hover {
    outline: 0;
    background: $btn__color__default__hover-background;
    color: $btn__color__default__hover;
    border: 1px solid $btn__color__default__hover-accent;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled].active,
  &[disabled]:active:focus,
  &[disabled].active:focus,
  &[disabled].active:hover {
    background: lighten($btn__color__default-background, 10%);
  }
}

.input-group-btn {
  font-size: inherit;
}

.action-dropdown-container {
  position: relative;
  display: inline-block;
}

.btn-primary-syn {
  color: $btn__color__primary;
  background: $btn__color__strata__default-background;

  &:focus {
    outline: 4px solid $btn__color__strata__default-background;
    outline-offset: 1px;
    background: $btn__color__strata__focus-background;
    border-radius: 6px;
  }

  &:hover {
    background: $btn__color__strata__hover-background;
  }
}

.btn-primary {
  color: $btn__color__primary;
  background: $btn__color__primary-background;
  border: 1px solid $btn__color__primary-accent;

  &:focus,
  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &.active:hover {
    background: $btn__color__primary__hover-background;
    border: 1px solid $btn__color__primary__hover-accent;
    outline: 0;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled].active,
  &[disabled]:active:focus,
  &[disabled].active:focus,
  &[disabled].active:hover {
    background: lighten($btn__color__primary-background, 10%);
  }
}

.btn-secondary {
  color: $btn__color__secondary;
  background: $btn__color__secondary-background;
  border: 1px solid $btn__color__secondary-accent;

  &:focus,
  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &.active:hover {
    background: $btn__color__secondary__hover-background;
    border: 1px solid $btn__color__secondary__hover-accent;
    outline: 0;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled].active,
  &[disabled]:active:focus,
  &[disabled].active:focus,
  &[disabled].active:hover {
    background: lighten($btn__color__secondary-background, 10%);
  }
}

.btn-danger {
  color: $btn__color__danger;
  background: $btn__color__danger-background;
  border: 1px solid $btn__color__danger-accent;

  &:focus,
  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &.active:hover {
    background: $btn__color__danger__hover-background;
    border: 1px solid $btn__color__danger__hover-accent;
    outline: 0;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled].active,
  &[disabled]:active:focus,
  &[disabled].active:focus,
  &[disabled].active:hover {
    background: lighten($btn__color__danger-background, 10%);
  }
}

.btn-info {
  color: $btn__color__info;
  background: $btn__color__info-background;
  border: 1px solid $btn__color__info-accent;

  &:focus,
  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &.active:hover {
    background: $btn__color__info__hover-background;
    border: 1px solid $btn__color__info__hover-accent;
    outline: 0;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled].active,
  &[disabled]:active:focus,
  &[disabled].active:focus,
  &[disabled].active:hover {
    background: lighten($btn__color__info-background, 10%);
  }
}

.btn-warning {
  color: $btn__color__warning;
  background: $btn__color__warning-background;
  border: 1px solid $btn__color__warning-accent;

  &:focus,
  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &.active:hover {
    background: $btn__color__warning__hover-background;
    border: 1px solid $btn__color__warning__hover-accent;
    outline: 0;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled].active,
  &[disabled]:active:focus,
  &[disabled].active:focus,
  &[disabled].active:hover {
    background: lighten($btn__color__warning-background, 10%);
  }
}

.btn-success {
  color: $btn__color__success;
  background: $btn__color__success-background;
  border: 1px solid $btn__color__success-accent;

  &:focus,
  &:hover,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &.active:hover {
    background: $btn__color__success__hover-background;
    border: 1px solid $btn__color__success__hover-accent;
    outline: 0;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled].active,
  &[disabled]:active:focus,
  &[disabled].active:focus,
  &[disabled].active:hover {
    background: lighten($btn__color__success-background, 10%);
  }
}

.btn-lg {
  font-size: ms(0);
  line-height: 1.2;
}

.btn-sm {
  font-size: ms(-2);
  line-height: 1.2;
}

.btn-xs {
  font-size: ms(-3);
}

.bulk-action-btn-container {
  margin-bottom: 30px;

  .btn.disabled {
    pointer-events: auto;

    &:focus {
      background: $color-app-blue;
    }

    &:hover {
      background-color: $color-app-blue;

      &:after {
        content: 'Please select one or more checkboxes.';
        position: absolute;
        bottom: 8px;
        color: $color-black;
        right: 15px;
        text-transform: initial;
      }
    }
  }
}

.bulk-action-btn-container .btn.disabled.delete {
  &:focus {
    background-color: $color-red;
    opacity: 0.65;
  }
  &:hover {
    background-color: darken($color-red, 10%);
  }
}

.resource-message-container {
  .close-message {
    @extend .close;
  }

  p {
    margin: 0;
    font-size: ms(-2);
  }
}
