.dashboard {
  margin-top: 20px;

  .card {
    width: 100%;
  }

  h2 {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  .btn-default {
    text-transform: capitalize;
    margin: 0 0 5px 5px;
    vertical-align: middle;
  }

  .clickable {
    display: inline-block;
    width: 100%;
    vertical-align: bottom;
    &:hover {
      background: $list_hover;
    }
  }

  p {
    color: $color-black;
    margin-bottom: 5px;
  }

  hr {
    margin: 0;
  }

  .card-icon {
    font-size: 3em;
  }

  svg {
    padding-top: 10px;
  }

  table {
    margin-bottom: 0;
  }
}
