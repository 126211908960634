@import 'colors';
@import '../../fonts/lato';
@import '../../fonts/montserrat';
@import '../../fonts/open_sans';
// Modular Scale Sass is why you see "ms()" everywhere in our Sass files
// https://github.com/modularscale/modularscale-sass
@import '~modularscale-sass/stylesheets/modular-scale';
@import '~typi/scss/typi';

// Font families
$font-family-sans: 'Helvetica', Arial, sans-serif;
$font-family-mono: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
$font-family-open-sans: 'Open Sans', $font-family-sans;
$font-family-lato: 'Lato', $font-family-sans;
$font-family-montserrat: 'Montserrat', $font-family-sans;
$font-family-montserrat-light: 'Montserrat Light', $font-family-sans;

$font-family-primary: $font-family-lato;
$font-family-secondary: $font-family-primary; // tmp
$font-family-secondary-alt: $font-family-primary; // tmp
$font-family-tertiary: $font-family-open-sans;
$font-family-quaternary: $font-family-montserrat;
$font-family-quaternary-alt: $font-family-montserrat-light;

.font-family-primary {
  font-family: $font-family-primary;
}

// Font sizes
$ms-base: 1em;
$ms-ratio: 1.2; // minor third

$line-height: 1.4;

// Mixin for providing responsive font sizes to a class.
// These map keys match the breakpoints found in ./_responsive.scss
@mixin font-size-map(
  $base-size,
  $go-smaller: true,
  $base-line-height: $line-height
) {
  // Anything smaller or larger needs a larger line height
  $large-line-height: calc(#{$base-line-height} + 0.2);

  $xxl: (ms($base-size + 1), calc(#{$base-line-height} + 0.2));
  $sm: (ms($base-size), #{$base-line-height});
  $xs: $sm;

  // Only go smaller if explicitly told so
  @if $go-smaller == true {
    $xs: (ms($base-size - 1), $large-line-height);
  }

  @include typi(
    (
      xs: $xs,
      sm: $sm,
      xxl: $xxl
    )
  );
}

@function calc-ems($expression) {
  @return #{$expression}em;
}

// Footnotes
.footnote {
  &:before {
    font-size: ms(-1);
    vertical-align: super;
    color: lighten($color-copy, 10%);
  }

  &.dagger:before {
    content: '\2020';
  }
  &.double-dagger:before {
    content: '\2021';
  }
}

.footnote__flag {
  &:after {
    font-size: ms(-1);
    vertical-align: super;
    color: lighten($color-copy, 10%);
  }

  &.dagger:after {
    content: '\2020';
  }
  &.double-dagger:after {
    content: '\2021';
  }
}
