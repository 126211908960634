/**
 * Here we are generating spacing utlity classes that can be used as a final way to create more space between elements.
 * They are difficult to override, so try to avoid them if possible.
 *
 * Example output:
 * .spacing__top-sm {
 *   margin-top: ms(0) !important;
 * }
 * ...
 */

$spacing-sides: (
  'top',
  'right',
  'bottom',
  'left'
);
$spacing-sizes: (
  sm: ms(0),
  md: ms(3),
  lg: ms(5)
);

@mixin generate-spacing {
  @each $side in $spacing-sides {
    @each $size-name, $size in $spacing-sizes {
      .spacing__#{$side}-#{$size-name} {
        margin-#{$side}: $size !important;
      }
    }
  }
}

@include generate-spacing();
