html {
  // Sets font size for all places this file covers; unsure why using
  // `font-family-base` in the Bootstrap variables override file doesn't work
  font-size: 16px;
}

body {
  font-family: $font-family-primary;
  font-variant-ligatures: common-ligatures;
  @include font-size-map(0);
}

p {
  font-weight: 400;
  margin-top: calc-ems($line-height / ($ms-ratio * 2));
  margin-bottom: calc-ems($line-height / ($ms-ratio));

  b,
  strong {
    font-weight: 700;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  $rhythm: calc-ems($line-height / ($ms-ratio * 4));

  margin-bottom: $rhythm;
  margin-top: $rhythm;

  &.header-border {
    padding-bottom: $rhythm / 2;
    margin-bottom: $rhythm / 2;
    border-bottom: 1px solid $color-neutral-04;
  }
}

h1 {
  font-weight: 300;
  @include font-size-map(4);
}

h2 {
  font-weight: 300;
  @include font-size-map(3);
}

h3 {
  font-weight: 400;
  @include font-size-map(2);
}

h4 {
  font-weight: 500;
  @include font-size-map(1);
}

h5 {
  font-weight: 700;
  @include font-size-map(0);
}

h6 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  @include font-size-map(-2);
}

.copy__feature {
  font-weight: 300;
  margin-top: calc-ems($line-height / ($ms-ratio));
  @include font-size-map(2);
}

.copy__body {
  font-weight: 400;
  @include font-size-map(0);
}

.copy__icon {
  font-weight: 400;
  width: ms(0);
  height: ms(0);
  @include font-size-map(0);
}

.copy__body-link,
.copy__body a {
  color: $link__color;
  border-bottom: 1px dotted $link__color;
  transition: color $transition-speed-easing, border $transition-speed-easing;

  &:hover,
  &:active {
    color: $link__color-active;
    text-decoration: none;
    border-color: transparent;
  }
}

.copy__body-meta {
  @include font-size-map(-1, false);
}

p small,
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.copy__body-caption {
  color: inherit;
  @include font-size-map(-2, false);
}

.copy__list,
.copy__body ul,
.copy__body ol {
  padding-left: ms(3);
  margin-bottom: calc-ems($line-height / $ms-ratio);
  @include font-size-map(0);

  > li {
    margin-bottom: calc-ems($line-height / ($ms-ratio * 2));
  }
}

ul.copy__list,
.copy__body ul {
  list-style-type: disc;
}
