// Clearfix for internally floated items
.box {
  overflow: hidden;
}

// Useful for inline elements that need to break
.break {
  display: block;
}

.inline {
  display: inline;
}

// Clear floated elements
.clear-float {
  clear: both;
}
