@font-face {
  font-family: 'Open Sans';
  src: url('/static/fonts/open-sans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/static/fonts/open-sans-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/static/fonts/open-sans-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/static/fonts/open-sans-bold-italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
