@import '../../stylesheets/settings/colors';
@import '../../stylesheets/settings/animation';

.tablesorter-header {
  border-bottom: 1px solid inherit;
  cursor: pointer;
  background: url('/static/img/sort-base.png') no-repeat right center;
  transition: background $transition-speed-easing,
    border $transition-speed-easing;

  &.tablesorter-headerAsc {
    background-image: url('/static/img/sort-asc.png');
  }

  &.tablesorter-headerDesc {
    background-image: url('/static/img/sort-desc.png');
  }

  &:not(.sorter-false):hover {
    border-color: $color__brand-primary;
  }

  &:focus,
  &.tablesorter-headerAsc,
  &.tablesorter-headerDesc {
    outline: 0;
    border-color: $color__brand-primary;
  }

  &.sorter-false {
    background: none;
    cursor: default;
  }
}

.tablesorter-header-inner {
  padding-right: 1em;
}
