@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/lato-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/lato-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/lato-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/lato-bold-italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
