$theme: 'default' !default;

// GENERAL

$color-white: #ffffff;

// SYNTELLIS BRAND COLORS

// Syntellis Action
$color-action-01: #10528e;
$color-action-04: #f0f8ff;

// Syntellis Brand
$color-brand-01: #5050cc;

// Syntellis Caution
$color-caution-01: #f5ad20;

// Syntellis Data
$color-data-04: #009292;

// Syntellis Neutral
$color-neutral-01: #343a3f;
$color-neutral-02: #697077;
$color-neutral-03: #a2a9b0;
$color-neutral-04: #dde1e6;
$color-neutral-05: #f8fafc;

// OLD

// Many unused color variables below - leaving as is for now in PR #10832
$color-blue: #53bde3;
$color-green: #89ae4d;
$color-blue-stronger: #2581c6;
$color-dark-blue: #396da2;
$color-darker-blue: #22629d;
$color-darkest-blue: #195786;
$color-dark-green: #6c9824;
$color-light-blue: #82d0ec;
$color-light-green: #afd96c;
$color-lighter-green: #d6f2aa;
//$color-white: #f5f5f5; // see this variable set above
$color-black: $color-neutral-01;
$color-pure-white: #ffffff;
$color-lighter-gray: #f4f3f3;
$color-light-gray: #dddddd;
$color-very-light-gray: #eaeaea;
$color-light-gray-blue: #c6d3e0;
$color-dark-gray: #6d6e71;
$color-darker-gray: #777777;
$color-red: #da2f2f;
$color-yellow: #d8bc2f;
$color-orange: #f2a93b;
$color-brand-dark: #20323c;
$color-stratasan-blue: #104b90;
$color-stratasan-green: #88b540;
$color-app-blue: #396da2;
$color-app-green: #89ae4d;

// Strata Colors
$color__strata-action-01: #007c9f;
$color__strata-action-02: #005f7a;

$color__stratasan-blue: $color-stratasan-blue;
$color__stratasan-green: $color-stratasan-green;
$color__brand-primary: $color-app-blue;
$color__brand-secondary: $color-app-green;
$color__brand-tertiary: $color-blue;
$color__brand-dark: $color-brand-dark;
$color__brand-quaternary: $color-light-green;
$color__accent-primary: $color-light-blue;
$color__accent-secondary: $color-dark-green;
$color__accent-tertiary: $color-light-green;
$color__accent-quaternary: $color-dark-gray;

$color-copy: $color-black !default;
$color-background: $color-very-light-gray !default;

$link__color: $color-copy !default;
$link__color-active: $color-light-gray !default;
$link__color-active-dark: $color-darkest-blue !default;

$color-danger: $color-red;
$color-warning: $color-orange;
$color-success: $color-lighter-green;

$color-subtext: darken($color-white, 2%);

// Links
$color-link-text: $color-blue-stronger;
$color-link-text-hover: $color-darkest-blue;

// Forms
$color-btn: $color-black !default;
$color-form-text: lighten($color-black, 20%);
$color-placeholder: lighten($color-form-text, 20%);

// Component default colors
$nav__color-background: $color-pure-white !default;
$nav__color: $color-copy !default;
$nav__color-active: $color-copy !default;
$nav__color-divider: $color__accent-quaternary !default;
$subnav__color-background: $color-black !default;
$subnav__color: $color-copy !default;
$subnav__color-active: $color-copy !default;
$subnav__color-divider: $color__accent-quaternary !default;
$subnav__color-special: $color-copy !default;

$table__color-border: $color-light-gray !default;
$table__color-header-border: $color-light-gray !default;

$card__color: $color-black !default;
$card__color-background: $color-pure-white !default;
$card__color-header: $card__color !default;
$card__color-accent: $color-light-gray !default;
$card__color-section: darken($card__color-background, 5%) !default;

$list_hover: $color-lighter-gray;

// Strata primary button colors
$btn__color__strata__default-background: $color__strata-action-01;
$btn__color__strata__hover-background: $color__strata-action-02;
$btn__color__strata__focus-background: $color__strata-action-01;

$btn__color__default: $color-black !default;
$btn__color__default-background: $color-pure-white !default;
$btn__color__default-accent: mix(
  $btn__color__default,
  $btn__color__default-background,
  30%
) !default;
$btn__color__default__hover: $color-pure-white !default;
$btn__color__default__hover-background: $color-black !default;
$btn__color__default__hover-accent: mix(
  $btn__color__default,
  $btn__color__default__hover-background,
  30%
) !default;

$btn__color__primary: $color-pure-white !default;
$btn__color__primary-background: $color__brand-primary !default;
$btn__color__primary-accent: mix(
  $btn__color__default,
  $btn__color__primary-background,
  30%
) !default;
$btn__color__primary__hover: $color-pure-white !default;
$btn__color__primary__hover-background: $color__brand-tertiary !default;
$btn__color__primary__hover-accent: mix(
  $btn__color__default,
  $btn__color__primary__hover-background,
  30%
) !default;

$btn__color__secondary: $color-pure-white !default;
$btn__color__secondary-background: $color__stratasan-green !default;
$btn__color__secondary-accent: $color__stratasan-green !default;
$btn__color__secondary__hover: $color-pure-white !default;
$btn__color__secondary__hover-background: darken(
  $color__stratasan-green,
  10%
) !default;
$btn__color__secondary__hover-accent: darken(
  $color__stratasan-green,
  10%
) !default;

$btn__color__info: $color-pure-white !default;
$btn__color__info-background: $color__brand-tertiary !default;
$btn__color__info-accent: $color__brand-tertiary !default;
$btn__color__info__hover: $color-pure-white !default;
$btn__color__info__hover-background: darken(
  $color__brand-tertiary,
  10%
) !default;
$btn__color__info__hover-accent: darken($color__brand-tertiary, 10%) !default;

$btn__color__danger: $color-pure-white !default;
$btn__color__danger-background: $color-red !default;
$btn__color__danger-accent: $color-red !default;
$btn__color__danger__hover: $color-pure-white !default;
$btn__color__danger__hover-background: darken($color-red, 10%) !default;
$btn__color__danger__hover-accent: darken($color-red, 10%) !default;

$btn__color__warning: $color-pure-white !default;
$btn__color__warning-background: $color-warning !default;
$btn__color__warning-accent: $color-warning !default;
$btn__color__warning__hover: $color-pure-white !default;
$btn__color__warning__hover-background: darken($color-warning, 10%) !default;
$btn__color__warning__hover-accent: darken($color-warning, 10%) !default;

$btn__color__success: $color-pure-white !default;
$btn__color__success-background: $color-success !default;
$btn__color__success-accent: $color-success !default;
$btn__color__success__hover: $color-pure-white !default;
$btn__color__success__hover-background: darken($color-success, 10%) !default;
$btn__color__success__hover-accent: darken($color-success, 10%) !default;
