#rename-crosswalk {
  display: inline;
  &__form {
    display: inline;
    &:active {
      display: inline-block;
      vertical-align: middle;
    }
    & .col-sm-12 {
      padding: 0;
    }
    & label {
      display: none;
    }
  }
  &__display {
    display: inline;
  }
}
